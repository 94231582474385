export const tableColumns = [
  {
    name: "Sr No",
    selector: (row, index) => index + 1,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row?.amount,
    sortable: true,
  },
  {
    name: "My Earning",
    selector: (row) => row?.subTotal,
    sortable: true,
  },
  {
    name: "Package ID",
    selector: (row) => row?.notary_id,
    sortable: true,
  },
  {
    name: "Invoice Number",
    selector: (row) => row.invoiceNumber,
    sortable: true,
  },
  {
    name: "Customer Email",
    selector: (row) => row?.email,
    sortable: true,
  },
  {
    name: "Payment Status",
    selector: (row) => row.payment_status,
    cell: (row) => (
      <span className={`badge text-bg-${row?.payment_status === "paid" ? "success"
        : "warning"}`
      }>
        {row?.payment_status ? row?.payment_status : ''}
      </span>
    ),
  },
];

export const tableData = [
  {
    id: 0,
    name: "US$50.60",
    age: "123456-INVOICE",
    position: "admin@gmail.com",
    company: "BUZZWORKS",
    startDate: "2023-12-17",
    salary: "$2,570.39",
    email: "",
    status: "Pending"
  },
  {
    id: 1,
    name: "US$80.60",
    age: "345678-INVOICE",
    position: "Accountant",
    company: "MARQET",
    startDate: "2023-12-26",
    salary: "$1,488.76",
    status: "Sent"
  },
];


function formatDateTime(dateTimeString) {
  const inputDate = new Date(dateTimeString);

  // Function to format the date in "DDMMYY" format
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  }

  // Function to format the time in AM/PM format
  function formatTime(date) {
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 baje ke baad 12 baje consider karo
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes} ${ampm}`;
  }

  // Getting the formatted date in "DDMMYY" format
  const formattedDate = formatDate(inputDate);

  // Getting the formatted time in AM/PM format
  const formattedTime = formatTime(inputDate);

  // Combining date and time
  const result = `${formattedDate} ${formattedTime}`;

  // Returning the formatted result
  return result;
}
export const trasaction_tableColumns = [
  {
    name: "PackageId",
    selector: (row) => row.package_id,
    sortable: true,
  },
  {
    name: "Signer name",
    selector: (row) => row.signer_name,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row) => row?.createdAt,
    cell: (row) => (

      <span >
        {formatDateTime(row?.createdAt)}
      </span>
    ),
  },
  {
    name: "Payment status",
    selector: (row) => row.payment_status,
    cell: (row) => (
      <span >
        {row?.payment_status}
      </span>
    ),
  },
  {
    name: "Notary Name",
    selector: (row) => row.notary_name,
    cell: (row) => (
      <span >
        {row?.notary_name}
      </span>
    ),
  },
  {
    name: "Transaction status",
    selector: (row) => row.package_status,
    cell: (row) => (
      <span >
        {row.package_status ? "Completed" : "In Progress"}
      </span>
    ),
  },
  {
    name: "Invoice",
    selector: (row) => row.package_status,
    cell: (row) => (
      <span >
        {row.package_status ? "Completed" : "In Progress"}
      </span>
    ),
  },
];

export const bankTransactionTable = [
  // {
  //   name: "Sr No",
  //   selector: (row, index) => index + 1,
  //   sortable: true,
  //   // style: {
  //   //   width: "30px",
  //   //   textAlign: "center",
  //   // },
  // },
  {
    name: "SR No",
    selector: (row) => row.name,
    cell: (row, index) => (<><span>{index + 1}</span>
    </>
    ),
    sortable: true,
  },

  // {
  //   name: "Sent By",
  //   selector: (row) => row?.sentBy,
  //   sortable: true,
  // },
  {
    name: "Transaction Type",
    selector: (row) => row?.type ? `Transferred to bank` : "",
    sortable: true,
    
  },
  {
    name: "Amount",
    selector: (row) => row?.amount,
    cell: (row) => (
      <span >
        {row?.amount}
      </span>
    ),
  },
  {
    name: "Merchant Fee",
    selector: (row) => row?.merchantFee,
    cell: (row) => (
      <span >
        {row?.merchantFee}
      </span>
    ),
  },
  {
    name: "Net Amount",
    selector: (row) => row?.merchantFee,
    cell: (row) => (
      <span >
        {row?.amount -row?.merchantFee}
      </span>
    ),
  },
  {
    name: "Created At",
    selector: (row) => row?.createdAt,
    cell: (row) => (
      <span >
        {formatDateTime(row?.createdAt)}
      </span>
    ),
  },

  // {
  //   name: "Notary Name",
  //   selector: (row) => row.notary_name,
  //   cell: (row) => (
  //     <span >
  //       {row?.notary_name}
  //     </span>
  //   ),
  // },
  // {
  //   name: "Transaction status",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
  // {
  //   name: "Invoice",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
];

export const trasaction_tableData = [
  {
    package_id: "QDFHLeCx2d1J3BoVIA-qqsno_Ao=",
    signer_name: "RInku",
    notary_name: "123456-INVOICE"
  }
];

import React from 'react'

function Success() {
  return (
    <div>
      done
    </div>
  )
}

export default Success

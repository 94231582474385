import {
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  Form,
  Table,
  Tooltip,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../layout/default";
import {
  Media,
  MediaGroup,
  MediaText,
  Icon,
  ChartLabel,
  ChartLegend,
  CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  Timeline,
  WorldMap,
} from "../../components";
import Block from "../../components/Block/Block";
import Select from "../../components/Form/Select";
import { Colors } from "../../utilities/index";
import hexRGB from "../../utilities/hexRGB";
import DataTable from "../../components/DataTable/DataTable";
import { tableColumns, tableData } from "../../components/DataTable/TableData";
import StripeApi from "../../api/stripe";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/global/reducer";

function InvoiceList() {
  let stripeApi = new StripeApi();
  const [state, setState] = useState({
    data: [],
    loading: true,
  });
  let { data } = state;

  let { user } = useSelector(selectUser);

  useEffect(() => {
    getInvoiceList();
  }, []);


  const getInvoiceList = async () => {
    setState({ ...state, loading: true, });
    await stripeApi
      .getInvoice(user?._id)
      .then((res) => {
        console.log("resss",res)
        if (res.success) {
          setState({ ...state, loading: false, data: res.data });
        }
      })
      .catch((err) => {
        setState({ ...state, loading: false, });
        console.log(err);
      });
  };
  console.log("data",data)
  return (
    <Layout title="Invoice" content="container">
      <Row>
        <Col md="12">
          <Block.Head>
            <Block.HeadBetween>
              <Block.HeadContent>
                <Block.Title tag="h2">Invoice</Block.Title>
              </Block.HeadContent>
            
            </Block.HeadBetween>
          </Block.Head>
        </Col>
        <Col md="12">
        {
          state.loading ? <Spinner animation="border" variant="secondary" /> :
          <DataTable
            tableClassName="data-table-head-light table-responsive"
            data={data}
            columns={tableColumns}
            expandableRows
          />
        }
          
        </Col>
      </Row>

    </Layout>
  );
}

export default InvoiceList;

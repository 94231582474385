
import BaseApi from "..";

function getAuthToken() {
  const data = {};

  // Iterate over all keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const value = localStorage.getItem(key);
    data[key] = value;
  }
  console.log("ldata=====", data)
  return data;
  // console.log("localStorage.getItem('authToken');=====", localStorage.getItem())
  // return localStorage.getItem('authToken');
}
class WalletApi extends BaseApi {
  async redeemFromWallet(data) {
    return await this.API({
      url: "/client/redeem-amount",
      method: "patch",
      data:{"userId":data}
    });
  }
}

export default WalletApi;
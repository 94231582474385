import {
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  Form,
  Table,
  Tooltip,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../layout/default";
import {
  Media,
  MediaGroup,
  MediaText,
  Icon,
  ChartLabel,
  ChartLegend,
  CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  Timeline,
  WorldMap,
} from "../../components";
import Block from "../../components/Block/Block";
import Select from "../../components/Form/Select";
import { Colors } from "../../utilities/index";
import hexRGB from "../../utilities/hexRGB";
import DataTable from "../../components/DataTable/DataTable";
import { trasaction_tableData } from "../../components/DataTable/TableData";
import Stripe from "stripe";
import NotaryApi from "../../api/notary";
import FormSelect from "./Select";
import { plan } from "../Jsons/plans";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/global/reducer";
import StripeApi from "../../api/stripe";
import Swal from "sweetalert2/src/sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { plansss } from "./array";
function TransactionList() {
  let{ package: packageId} = useParams()
  let id = packageId
  const { user } = useSelector(selectUser);
  const IpenPlan = plan.find((item) => item.product_id === user?.product_id);
  let notaryApi = new NotaryApi();
const STRIPE_KEY = require("../../config").STRIPE_KEY;
  const stripe = new Stripe(STRIPE_KEY);
  let navigate = useNavigate();
  let notary = new NotaryApi();
  const [state, SetState] = useState({
    data: [],
    stripe_Accounts: plansss,
    LineItem: [{ amount: 0, name: "" }],
    loading: true,
  });
  const [notary_id, Setnotary_id] = useState(null);
  const [loader, setLoader] = useState(null);
  const [emails, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [getSigner, setgetSigner] = useState([]);
  const handleShowModal = (id) => {
    SetState({
      ...state,
      LineItem: [...LineItem, { name: "", amount: 0 }],
    });
    // getStripeAccounts();
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  let { data, stripe_Accounts, LineItem } = state;
  var totalAmountLineItem = LineItem.map((item) => item.amount).reduce(
    function (accumulator, currentValue) {
      return Number(accumulator) + Number(currentValue);
    },
    0
  );

  const ifPlanActive = user.product_id ? true : false;
  // var AON_fee = plan.filter((item) => item.product_id == user.product_id).length
  //   ? plan.filter((item) => item.product_id == user.product_id)[0].AYON_fee
  //   : 0;
  var AON_fee = 25
  useEffect(() => {
    getNotaries();
    // getStripeAccounts();
  }, []);
  const getNotaries = async () => {
    SetState({ ...state, loading: true });
    await notary
      .list(user)
      .then(async(res) => {
    if(id){
      if(res.data.filter((item) => item?.notary_id == id).length){
      Setnotary_id(id);
      handleShowModal();

      setgetSigner(
        res.data.filter((item) => item?.notary_id == id).length
          ? res.data.filter((item) => item?.notary_id == id)[0]
              ?.signerDetails
          : []
      );}
    }
    console.log("res.data",res.data)
        SetState({ ...state, loading: false, data: res.data });
        // setLoading(res.data);
      })
      .catch((err) => {
        SetState({ ...state, loading: false });
        // setLoading(false);
        console.log(err);
      });
  };

  const getStripeAccounts = async () => {
    await stripe.products.list({ limit: 20 }, (err, products) => {
      if (err) {
        console.error(err);
      } else {
        SetState({
          ...state,
          stripe_Accounts: products?.data?.filter(
            (item) => !item.name.includes("AYIN")
          ),
        });
      }
    });
  };
  function formatDateTime(dateTimeString) {
    const inputDate = new Date(dateTimeString);

    // Function to format the date in "DDMMYY" format
    function formatDate(date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(2);

      return `${day}/${month}/${year}`;
    }

    // Function to format the time in AM/PM format
    function formatTime(date) {
      let hours = date.getHours();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // 0 baje ke baad 12 baje consider karo
      const minutes = date.getMinutes().toString().padStart(2, "0");

      return `${hours}:${minutes} ${ampm}`;
    }

    // Getting the formatted date in "DDMMYY" format
    const formattedDate = formatDate(inputDate);

    // Getting the formatted time in AM/PM format
    const formattedTime = formatTime(inputDate);

    // Combining date and time
    const result = `${formattedDate} ${formattedTime}`;

    // Returning the formatted result
    return result;
  }
  const trasaction_tableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Signer name",
      selector: (row) => row.signer_name,
      sortable: true,
    },
    {
      name: "Package ID",
      selector: (row) => row?.package_id,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row?.createdAt,
      cell: (row) => <span>{formatDateTime(row?.createdAt)}</span>,
    },
    {
      name: "Payment status",
      selector: (row) => row.payment_status,
      cell: (row) => (
        <span
          className={`badge text-bg-${
            row.payment_status === "Completed"
              ? "success"
              : row.payment_status === "pending"
              ? "warning"
              : "primary"
          }`}
        >
          {row.payment_status ? row.payment_status : ""}
        </span>
      ),
    },
    {
      name: "Notary Name",
      selector: (row) => row.notary_name,
      cell: (row) => <span>{row?.notary_name}</span>,
    },
    {
      name: "Transaction status",
      selector: (row) => row.status,
      cell: (row) => (
        <span
          className={`badge text-bg-${
            row.package_status === "complete" ? "success" : "warning"
          }`}
        >
          <span>{row.package_status}</span>
        </span>
      ),
    },
    {
      name: "Invoice",
      selector: (row) => row.package_status,
      cell: (row) => (
        <>
          {row.package_status !== "complete" ? (
            "--"
          ) : row.invoiceRaised ? (
            <span>INVOICE SENT</span>
          ) : (
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={() => {
                    if (!user?.product_id) {
                      navigate("/view-profile_");
                    } else {
                      console.log("row.notary_id", row.notary_id);
                      Setnotary_id(row.notary_id);
                      handleShowModal();
                      setgetSigner(
                        data.filter((item) => item?._id == row._id).length
                          ? data.filter((item) => item?._id == row._id)[0]
                              ?.signerDetails
                          : []
                      );
                    }
                  }}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  style={{ width: "150px" }}
                  variant="primary"
                  onClick={async () => {
                    if (!user?.squarePaymentDone) {
                      navigate("/view-profile_");
                    } else {
                      Setnotary_id(row.notary_id);
                      handleShowModal();
                      setgetSigner(
                        data.filter((item) => item?._id == row._id).length
                          ? data.filter((item) => item?._id == row._id)[0]
                              ?.signerDetails
                          : []
                      );
                    }
                  }}
                >
                  <Icon name="plus" />
                  <span>Create Invoice</span>
                </Button>
              </li>
            </ul>
          )}
        </>
      ),
    },
  ];
  var sumFOrInvoice = totalAmountLineItem + AON_fee;
  console.log("LineItem", LineItem);
  let stripeApi = new StripeApi();
  async function createInvoice() {
    try {
      setLoader(true);
      const customer = await stripe.customers.create({
        email: emails,
      });
      const lineItems = LineItem.length
        ? LineItem.filter(function (obj) {
            return obj.amount !== "" && obj.name !== "";
          })
        : [];
      const totalAmount = lineItems.reduce(
        (total, item) => total + item.amount * item.quantity,
        0
      );
      console.log("lineItems", totalAmount);
      const invoice = await stripe.invoices.create({
        customer: customer.id,
        collection_method: "send_invoice",
        days_until_due: 30,
        currency: "usd",
      });
      handleCloseModal();
      if (invoice) {
        for (const item of lineItems) {
          await stripe.invoiceItems.create({
            customer: invoice.customer,
            amount: item.amount * 100,
            currency: "usd",
            description: item.name,
            invoice: invoice.id,
          });
        }
        await stripe.invoiceItems.create({
          customer: invoice.customer,
          amount: AON_fee * 100,
          currency: "usd",
          description: "AON Session fee",
          invoice: invoice.id,
        });

        // await notaryApi.update({ _id: user._id, invoiceId: invoice?.id,notary_id :notary_id }).then(async(res) => {
        //   if (res.success) {
        //     SetState({ ...state, data: res.data })
        //     Swal.fire({
        //       icon: "success",
        //       title: "Good job!",
        //       text: res.message,
        //     });
        //   } else {
        //     Swal.fire({
        //       icon: "error",
        //       title: res.message,
        //     });
        //   }
        // }).catch((err) => console.error("errr", err))
      }
      console.log("Invoice created:", invoice);
      setLoader(true);
      console.log("totalAmountLineItem-------", totalAmountLineItem);
      await stripe.invoices.sendInvoice(invoice.id).then(async (res) => {
        console.log("send Invoice mail", res);
        await stripeApi
          .create_invoice({
            invoiceNumber: res.number,
            notary_id: notary_id,
            invoiceId: res.id,
            amount: sumFOrInvoice,
            userId: user?._id,
            email: emails,
            subTotal: totalAmountLineItem,
          })
          .then(async (res) => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: res.message,
              });
            }
            getNotaries();
            // getStripeAccounts();
            setLoader(false);
          })
          .catch((err) => console.error("errr", err));
      });
    } catch (error) {
      console.error("Error creating invoice:", error);
    }
  }
  console.log("notary_id", notary_id);



  const handleDownload = async () => {
    try {
      // const url = "http://localhost:9001/notary/download-zip"
      // const url = "http://localhost:9001/notary/download-zip"
      const url =  `http://localhost:9001/notary/download-zip/98Q2B7XU9QT2DN3A44NBNy8uTZ0=` // for sandbox
      // const url =  `https://api.ayininternationalinc.org/notary/download-zip/${packageId}`
    ;

      // Make the GET request to fetch the ZIP file
      const response = await axios.get(url, {
     
        responseType: 'blob', // Important to ensure the response is a blob
      });
      

      // Create a URL for the blob
      const blob = new Blob([response.data], { type: 'application/zip' });
      const downloadUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'documents.zip'; // The name of the file to be downloaded
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };


  return (
    <Layout title="Invoice">
      <Row>
        <Col md="12">
          <Block.Head>
            <Block.HeadBetween>
              <Block.HeadContent>
                <Block.Title tag="h2">Transactions</Block.Title>

              </Block.HeadContent>
              <Block.HeadContent></Block.HeadContent>
            </Block.HeadBetween>
          </Block.Head>
        </Col>
        {/* <Button className="w-25 h-25" onClick={handleDownload} > download zip</Button> */}
        <Col md="12">
          {/* Show Loading... if loading is true and show DataTable when API call complete */}

          {state.loading ? (
            <Spinner animation="border" variant="secondary" />
          ) : (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={data}
              columns={trasaction_tableColumns}
              expandableRows
            />
          )}
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#" className="p-3">
            <Row className="g-3">
              <Col lg="12">
                <Form.Group className="form-group">
                  <div className="form-control-wrap">
                    <Select onChange={(e) => setEmail(e.target.value)}>
                      <option value="">Select Customer</option>
                      {getSigner.map((item) => {
                        return (
                          <>
                            <option value={item.email}>{item.email}</option>
                          </>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                {LineItem.map((item, i) => {
                  return (
                    <div
                      className="d-flex gap-2 align-items-center justify-content-between mt-1"
                      key={i}
                    >
                      <div className="d-flex flex-column gap-2 w-75">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap d-flex gap-2">
                            <Form.Group className="form-group">
                              <div
                                className="form-control-wrap"
                                style={{ width: "300px" }}
                              >
                                {stripe_Accounts.length && (
                                  <Select
                                    style={{ width: "200px" }}
                                    onChange={(e) => {
                                      const selectedAccountIndex =
                                        e.target.value;
                                      const updatedLineItem = [...LineItem];
                                      updatedLineItem[i] = {
                                        ...updatedLineItem[i],
                                        name: e.target.value,
                                      };
                                      SetState({
                                        ...state,
                                        LineItem: updatedLineItem,
                                      });
                                    }}
                                  >
                                    <option value="">Line Item</option>
                                    {IpenPlan?.product_id ===
                                    "prod_PAOSyHjjVSQu8x"
                                      ? stripe_Accounts?.filter((item) =>
                                          item?.name?.includes("IPEN")
                                        ).length &&
                                        stripe_Accounts
                                          ?.filter((item) =>
                                            item?.name?.includes("IPEN")
                                          )
                                          .map((item, index) => (
                                            <option
                                              key={index}
                                              value={item?.name}
                                            >
                                              {item.name}
                                            </option>
                                          ))
                                      : stripe_Accounts.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item?.name}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                  </Select>
                                )}
                              </div>
                            </Form.Group>
                            <Form.Control
                              id="firstname"
                              type="number"
                              value={
                                LineItem[i]?.amount > 0 && LineItem[i]?.amount
                              }
                              onChange={(e) => {
                                const selectedAccountIndex = e.target.value;
                                console.log("e.target.value", e.target.value);
                                const updatedLineItem = [...LineItem];
                                updatedLineItem[i] = {
                                  ...updatedLineItem[i],
                                  amount: e.target.value,
                                };
                                SetState({
                                  ...state,
                                  LineItem: updatedLineItem,
                                });
                              }}
                              placeholder="Amount"
                              style={{ width: "120px" }}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      {LineItem.length > 1 && (
                        <Button
                          variant="primary"
                          onClick={() => {
                            const updatedLineItem = [...LineItem];
                            updatedLineItem.splice(i, 1);
                            SetState({ ...state, LineItem: updatedLineItem });
                          }}
                        >
                          <Icon name="cross" />
                        </Button>
                      )}
                      {i === LineItem.length - 1 && (
                        <Button
                          variant="primary"
                          disabled={
                            (!LineItem[LineItem.length - 1].amount ||
                              LineItem[LineItem.length - 1].amount == 0) &&
                            !LineItem[LineItem.length - 1].price
                          }
                          onClick={() =>
                            SetState({
                              ...state,
                              LineItem: [...LineItem, { price: "", amount: 0 }],
                            })
                          }
                        >
                          <Icon name="plus" />
                        </Button>
                      )}
                    </div>
                  );
                })}
              </Col>

              <Col lg="12">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex">
                    <span className="w-75">Sub Total - </span>
                    <span>${totalAmountLineItem}</span>
                  </div>
                  <div className="d-flex">
                    <span className="w-75">AON Session Fee </span>
                    <span>${AON_fee}</span>
                  </div>
                  <hr></hr>
                  <div className="fw-bold d-flex">
                    <span className="w-75">Total </span>
                    <span>${sumFOrInvoice}</span>
                  </div>
                </div>
              </Col>
              <Col lg="12" className="d-flex justify-content-center mt-5">
                {loader ? (
                  <Button className="fw-bold" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    disabled={!emails || !totalAmountLineItem}
                    onClick={createInvoice}
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default TransactionList;


// import {
//   Row,
//   Col,
//   Card,
//   Dropdown,
//   Button,
//   Modal,
//   Form,
//   Table,
//   Tooltip,
//   OverlayTrigger,
//   ListGroup,
// } from "react-bootstrap";
// import Spinner from "react-bootstrap/Spinner";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import Layout from "../../layout/default";
// import {
//   Media,
//   MediaGroup,
//   MediaText,
//   Icon,
//   ChartLabel,
//   ChartLegend,
//   CustomDropdownMenu,
//   CustomDropdownToggle,
//   OverlineTitle,
//   Timeline,
//   WorldMap,
// } from "../../components";
// import Block from "../../components/Block/Block";
// import Select from "../../components/Form/Select";
// import { Colors } from "../../utilities/index";
// import hexRGB from "../../utilities/hexRGB";
// import DataTable from "../../components/DataTable/DataTable";
// import { trasaction_tableData } from "../../components/DataTable/TableData";
// import Stripe from "stripe";
// import NotaryApi from "../../api/notary";
// import FormSelect from "./Select";
// import { plan } from "../Jsons/plans";
// import { useSelector } from "react-redux";
// import { selectUser } from "../../redux/global/reducer";
// import StripeApi from "../../api/stripe";
// import Swal from "sweetalert2/src/sweetalert2";
// import { useNavigate, useParams } from "react-router-dom";
// import { plansss } from "./array";
// function TransactionList() {
//   // let{id} = useParams()
//   const { user } = useSelector(selectUser);
//   const IpenPlan = plan.find((item) => item.product_id === user?.product_id);
//   let notaryApi = new NotaryApi();
// const STRIPE_KEY = require("../../config").STRIPE_KEY;
//   const stripe = new Stripe(STRIPE_KEY);
//   let navigate = useNavigate();
//   let notary = new NotaryApi();
//   const [state, SetState] = useState({
//     data: [],
//     stripe_Accounts: plansss,
//     LineItem: [{ amount: 0, name: "" }],
//     loading: true,
//   });
//   const [notary_id, Setnotary_id] = useState(null);
//   const [loader, setLoader] = useState(null);
//   const [emails, setEmail] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [getSigner, setgetSigner] = useState([]);
//   const handleShowModal = (id) => {
//     SetState({
//       ...state,
//       LineItem: [...LineItem, { name: "", amount: 0 }],
//     });
//     // getStripeAccounts();
//     setShowModal(true);
//   };
//   const handleCloseModal = () => setShowModal(false);
//   let { data, stripe_Accounts, LineItem } = state;
//   var totalAmountLineItem = LineItem.map((item) => item.amount).reduce(
//     function (accumulator, currentValue) {
//       return Number(accumulator) + Number(currentValue);
//     },
//     0
//   );

//   const ifPlanActive = user.product_id ? true : false;
//   var AON_fee = plan.filter((item) => item.product_id == user.product_id).length
//     ? plan.filter((item) => item.product_id == user.product_id)[0].AYON_fee
//     : 0;
//   useEffect(() => {
//     getNotaries();
//     // getStripeAccounts();
//   }, []);
//   const getNotaries = async () => {
//     SetState({ ...state, loading: true });
//     await notary
//       .list(user)
//       .then(async(res) => {
//     if(id){
//       Setnotary_id(id);
//       handleShowModal();

//       setgetSigner(
//         res.data.filter((item) => item?.notary_id == id).length
//           ? res.data.filter((item) => item?.notary_id == id)[0]
//               ?.signerDetails
//           : []
//       );
//     }
//     console.log("res.data",res.data)
//         SetState({ ...state, loading: false, data: res.data });
//         // setLoading(res.data);
//       })
//       .catch((err) => {
//         SetState({ ...state, loading: false });
//         // setLoading(false);
//         console.log(err);
//       });
//   };

//   const getStripeAccounts = async () => {
//     await stripe.products.list({ limit: 20 }, (err, products) => {
//       if (err) {
//         console.error(err);
//       } else {
//         SetState({
//           ...state,
//           stripe_Accounts: products?.data?.filter(
//             (item) => !item.name.includes("AYIN")
//           ),
//         });
//       }
//     });
//   };
//   function formatDateTime(dateTimeString) {
//     const inputDate = new Date(dateTimeString);

//     // Function to format the date in "DDMMYY" format
//     function formatDate(date) {
//       const day = date.getDate().toString().padStart(2, "0");
//       const month = (date.getMonth() + 1).toString().padStart(2, "0");
//       const year = date.getFullYear().toString().slice(2);

//       return `${day}/${month}/${year}`;
//     }

//     // Function to format the time in AM/PM format
//     function formatTime(date) {
//       let hours = date.getHours();
//       const ampm = hours >= 12 ? "PM" : "AM";
//       hours = hours % 12;
//       hours = hours ? hours : 12; // 0 baje ke baad 12 baje consider karo
//       const minutes = date.getMinutes().toString().padStart(2, "0");

//       return `${hours}:${minutes} ${ampm}`;
//     }

//     // Getting the formatted date in "DDMMYY" format
//     const formattedDate = formatDate(inputDate);

//     // Getting the formatted time in AM/PM format
//     const formattedTime = formatTime(inputDate);

//     // Combining date and time
//     const result = `${formattedDate} ${formattedTime}`;

//     // Returning the formatted result
//     return result;
//   }
//   const trasaction_tableColumns = [
//     {
//       name: "Sr.No",
//       selector: (row, index) => index + 1,
//       sortable: true,
//     },
//     {
//       name: "Signer name",
//       selector: (row) => row.signer_name,
//       sortable: true,
//     },
//     {
//       name: "Package ID",
//       selector: (row) => row?.package_id,
//       sortable: true,
//     },
//     {
//       name: "Created At",
//       selector: (row) => row?.createdAt,
//       cell: (row) => <span>{formatDateTime(row?.createdAt)}</span>,
//     },
//     {
//       name: "Payment status",
//       selector: (row) => row.payment_status,
//       cell: (row) => (
//         <span
//           className={`badge text-bg-${
//             row.payment_status === "Completed"
//               ? "success"
//               : row.payment_status === "pending"
//               ? "warning"
//               : "primary"
//           }`}
//         >
//           {row.payment_status ? row.payment_status : ""}
//         </span>
//       ),
//     },
//     {
//       name: "Notary Name",
//       selector: (row) => row.notary_name,
//       cell: (row) => <span>{row?.notary_name}</span>,
//     },
//     {
//       name: "Transaction status",
//       selector: (row) => row.status,
//       cell: (row) => (
//         <span
//           className={`badge text-bg-${
//             row.package_status === "complete" ? "success" : "warning"
//           }`}
//         >
//           <span>{row.package_status}</span>
//         </span>
//       ),
//     },
//     {
//       name: "Invoice",
//       selector: (row) => row.package_status,
//       cell: (row) => (
//         <>
//           {row.package_status !== "complete" ? (
//             "--"
//           ) : row.invoiceRaised ? (
//             <span>INVOICE SENT</span>
//           ) : (
//             <ul className="d-flex">
//               <li>
//                 <Button
//                   className="d-md-none"
//                   size="md"
//                   variant="primary"
//                   onClick={() => {
//                     if (!user?.product_id) {
//                       navigate("/view-profile_");
//                     } else {
//                       console.log("row.notary_id", row.notary_id);
//                       Setnotary_id(row.notary_id);
//                       handleShowModal();
//                       setgetSigner(
//                         data.filter((item) => item?._id == row._id).length
//                           ? data.filter((item) => item?._id == row._id)[0]
//                               ?.signerDetails
//                           : []
//                       );
//                     }
//                   }}
//                 >
//                   <Icon name="plus" />
//                   <span>Add</span>
//                 </Button>
//               </li>
//               <li>
//                 <Button
//                   className="d-none d-md-inline-flex"
//                   style={{ width: "150px" }}
//                   variant="primary"
//                   onClick={async () => {
//                     if (!user?.product_id) {
//                       navigate("/view-profile_");
//                     } else {
//                       Setnotary_id(row.notary_id);
//                       handleShowModal();
//                       setgetSigner(
//                         data.filter((item) => item?._id == row._id).length
//                           ? data.filter((item) => item?._id == row._id)[0]
//                               ?.signerDetails
//                           : []
//                       );
//                     }
//                   }}
//                 >
//                   <Icon name="plus" />
//                   <span>Create Invoice</span>
//                 </Button>
//               </li>
//             </ul>
//           )}
//         </>
//       ),
//     },
//   ];
//   var sumFOrInvoice = totalAmountLineItem + AON_fee;
//   console.log("LineItem", LineItem);
//   let stripeApi = new StripeApi();
//   async function createInvoice() {
//     try {
//       setLoader(true);
//       const customer = await stripe.customers.create({
//         email: emails,
//       });
//       const lineItems = LineItem.length
//         ? LineItem.filter(function (obj) {
//             return obj.amount !== "" && obj.name !== "";
//           })
//         : [];
//       const totalAmount = lineItems.reduce(
//         (total, item) => total + item.amount * item.quantity,
//         0
//       );
//       console.log("lineItems", totalAmount);
//       const invoice = await stripe.invoices.create({
//         customer: customer.id,
//         collection_method: "send_invoice",
//         days_until_due: 30,
//         currency: "usd",
//       });
//       handleCloseModal();
//       if (invoice) {
//         for (const item of lineItems) {
//           await stripe.invoiceItems.create({
//             customer: invoice.customer,
//             amount: item.amount * 100,
//             currency: "usd",
//             description: item.name,
//             invoice: invoice.id,
//           });
//         }
//         await stripe.invoiceItems.create({
//           customer: invoice.customer,
//           amount: AON_fee * 100,
//           currency: "usd",
//           description: "AON Session fee",
//           invoice: invoice.id,
//         });

//         // await notaryApi.update({ _id: user._id, invoiceId: invoice?.id,notary_id :notary_id }).then(async(res) => {
//         //   if (res.success) {
//         //     SetState({ ...state, data: res.data })
//         //     Swal.fire({
//         //       icon: "success",
//         //       title: "Good job!",
//         //       text: res.message,
//         //     });
//         //   } else {
//         //     Swal.fire({
//         //       icon: "error",
//         //       title: res.message,
//         //     });
//         //   }
//         // }).catch((err) => console.error("errr", err))
//       }
//       console.log("Invoice created:", invoice);
//       setLoader(true);
//       console.log("totalAmountLineItem-------", totalAmountLineItem);
//       await stripe.invoices.sendInvoice(invoice.id).then(async (res) => {
//         console.log("send Invoice mail", res);
//         await stripeApi
//           .create_invoice({
//             invoiceNumber: res.number,
//             notary_id: notary_id,
//             invoiceId: res.id,
//             amount: sumFOrInvoice,
//             userId: user?._id,
//             email: emails,
//             subTotal: totalAmountLineItem,
//           })
//           .then(async (res) => {
//             if (res.success) {
//               Swal.fire({
//                 icon: "success",
//                 title: "Good job!",
//                 text: res.message,
//               });
//             } else {
//               Swal.fire({
//                 icon: "error",
//                 title: res.message,
//               });
//             }
//             getNotaries();
//             // getStripeAccounts();
//             setLoader(false);
//           })
//           .catch((err) => console.error("errr", err));
//       });
//     } catch (error) {
//       console.error("Error creating invoice:", error);
//     }
//   }
//   console.log("notary_id", notary_id);
//   return (
//     <Layout title="Invoice">
//       <Row>
//         <Col md="12">
//           <Block.Head>
//             <Block.HeadBetween>
//               <Block.HeadContent>
//                 <Block.Title tag="h2">Transactions</Block.Title>
//               </Block.HeadContent>
//               <Block.HeadContent></Block.HeadContent>
//             </Block.HeadBetween>
//           </Block.Head>
//         </Col>
//         <Col md="12">
//           {/* Show Loading... if loading is true and show DataTable when API call complete */}

//           {state.loading ? (
//             <Spinner animation="border" variant="secondary" />
//           ) : (
//             <DataTable
//               tableClassName="data-table-head-light table-responsive"
//               data={data}
//               columns={trasaction_tableColumns}
//               expandableRows
//             />
//           )}
//         </Col>
//       </Row>
//       <Modal
//         show={showModal}
//         onHide={handleCloseModal}
//         backdrop="static"
//         size="lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Create Invoice</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form action="#" className="p-3">
//             <Row className="g-3">
//               <Col lg="12">
//                 <Form.Group className="form-group">
//                   <div className="form-control-wrap">
//                     <Select onChange={(e) => setEmail(e.target.value)}>
//                       <option value="">Select Customer</option>
//                       {getSigner.map((item) => {
//                         return (
//                           <>
//                             <option value={item.email}>{item.email}</option>
//                           </>
//                         );
//                       })}
//                     </Select>
//                   </div>
//                 </Form.Group>
//               </Col>
//               <Col lg="12">
//                 {LineItem.map((item, i) => {
//                   return (
//                     <div
//                       className="d-flex gap-2 align-items-center justify-content-between mt-1"
//                       key={i}
//                     >
//                       <div className="d-flex flex-column gap-2 w-75">
//                         <Form.Group className="form-group">
//                           <div className="form-control-wrap d-flex gap-2">
//                             <Form.Group className="form-group">
//                               <div
//                                 className="form-control-wrap"
//                                 style={{ width: "300px" }}
//                               >
//                                 {stripe_Accounts.length && (
//                                   <Select
//                                     style={{ width: "200px" }}
//                                     onChange={(e) => {
//                                       const selectedAccountIndex =
//                                         e.target.value;
//                                       const updatedLineItem = [...LineItem];
//                                       updatedLineItem[i] = {
//                                         ...updatedLineItem[i],
//                                         name: e.target.value,
//                                       };
//                                       SetState({
//                                         ...state,
//                                         LineItem: updatedLineItem,
//                                       });
//                                     }}
//                                   >
//                                     <option value="">Line Item</option>
//                                     {IpenPlan?.product_id ===
//                                     "prod_PAOSyHjjVSQu8x"
//                                       ? stripe_Accounts?.filter((item) =>
//                                           item?.name?.includes("IPEN")
//                                         ).length &&
//                                         stripe_Accounts
//                                           ?.filter((item) =>
//                                             item?.name?.includes("IPEN")
//                                           )
//                                           .map((item, index) => (
//                                             <option
//                                               key={index}
//                                               value={item?.name}
//                                             >
//                                               {item.name}
//                                             </option>
//                                           ))
//                                       : stripe_Accounts.map((item, index) => (
//                                           <option
//                                             key={index}
//                                             value={item?.name}
//                                           >
//                                             {item.name}
//                                           </option>
//                                         ))}
//                                   </Select>
//                                 )}
//                               </div>
//                             </Form.Group>
//                             <Form.Control
//                               id="firstname"
//                               type="number"
//                               value={
//                                 LineItem[i]?.amount > 0 && LineItem[i]?.amount
//                               }
//                               onChange={(e) => {
//                                 const selectedAccountIndex = e.target.value;
//                                 console.log("e.target.value", e.target.value);
//                                 const updatedLineItem = [...LineItem];
//                                 updatedLineItem[i] = {
//                                   ...updatedLineItem[i],
//                                   amount: e.target.value,
//                                 };
//                                 SetState({
//                                   ...state,
//                                   LineItem: updatedLineItem,
//                                 });
//                               }}
//                               placeholder="Amount"
//                               style={{ width: "120px" }}
//                             />
//                           </div>
//                         </Form.Group>
//                       </div>
//                       {LineItem.length > 1 && (
//                         <Button
//                           variant="primary"
//                           onClick={() => {
//                             const updatedLineItem = [...LineItem];
//                             updatedLineItem.splice(i, 1);
//                             SetState({ ...state, LineItem: updatedLineItem });
//                           }}
//                         >
//                           <Icon name="cross" />
//                         </Button>
//                       )}
//                       {i === LineItem.length - 1 && (
//                         <Button
//                           variant="primary"
//                           disabled={
//                             (!LineItem[LineItem.length - 1].amount ||
//                               LineItem[LineItem.length - 1].amount == 0) &&
//                             !LineItem[LineItem.length - 1].price
//                           }
//                           onClick={() =>
//                             SetState({
//                               ...state,
//                               LineItem: [...LineItem, { price: "", amount: 0 }],
//                             })
//                           }
//                         >
//                           <Icon name="plus" />
//                         </Button>
//                       )}
//                     </div>
//                   );
//                 })}
//               </Col>

//               <Col lg="12">
//                 <div className="d-flex flex-column gap-2">
//                   <div className="d-flex">
//                     <span className="w-75">Sub Total - </span>
//                     <span>${totalAmountLineItem}</span>
//                   </div>
//                   <div className="d-flex">
//                     <span className="w-75">AON Session Fee </span>
//                     <span>${AON_fee}</span>
//                   </div>
//                   <hr></hr>
//                   <div className="fw-bold d-flex">
//                     <span className="w-75">Total </span>
//                     <span>${sumFOrInvoice}</span>
//                   </div>
//                 </div>
//               </Col>
//               <Col lg="12" className="d-flex justify-content-center mt-5">
//                 {loader ? (
//                   <Button className="fw-bold" type="button" disabled>
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     Loading...
//                   </Button>
//                 ) : (
//                   <Button
//                     variant="primary"
//                     disabled={!emails || !totalAmountLineItem}
//                     onClick={createInvoice}
//                   >
//                     Create
//                   </Button>
//                 )}
//               </Col>
//             </Row>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </Layout>
//   );
// }

// export default TransactionList;

import React, { useEffect } from "react";
import { ScrollToTop } from "../components";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/global/reducer"
import PublicRoute from "./public";
import PrivateRoute from "./private";
import { useParams } from "react-router-dom";
function Router() {
  const currentuser = useSelector(selectUser);
  const { package: packageID, signerId, packageStatus } = useParams();
  if (!currentuser.user && window.location.pathname.includes("/transaction-list") && packageID) {
    // let package_id = window.location.pathname.split("/")[2]
    sessionStorage.setItem("package_id", packageID)
  } else {
    // sessionStorage.removeItem("package_id")
  }
  return (
    <ScrollToTop>
      {!currentuser?.user ? (
        <PublicRoute />
      ) : (
        <PrivateRoute />
      )}
    </ScrollToTop>
  );
}

export default Router;

// import React, { useEffect } from "react";
// import { ScrollToTop } from "../components";
// import { useSelector } from "react-redux";
// import {selectUser} from "../redux/global/reducer"
// import PublicRoute from "./public";
// import PrivateRoute from "./private";
// function Router() {
//   const currentuser = useSelector(selectUser);

//     if(!currentuser.user && window.location.pathname.includes("/transaction-list")){
//       let package_id = window.location.pathname.split("/")[2]
//       sessionStorage.setItem("package_id",package_id)
//     }else{
//       // sessionStorage.removeItem("package_id")
//     }
//   return (
//     <ScrollToTop>
//       {!currentuser?.user ? (
//         <PublicRoute/>
//       ) : (
//         <PrivateRoute/>
//       )}
//     </ScrollToTop>
//   );
// }

// export default Router;
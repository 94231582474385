import BaseApi from "..";

class BankTransactionApi extends BaseApi {
  // async create_subscription(data) {
  //   console.log("data",)
  //   return await this.API({
  //     url: "stripe/stripe_checkout/subscription",
  //     method: "post",
  //     data: data
  //   });
  // };
  // async create_invoice(data) {
  //   console.log("data",)
  //   return await this.API({
  //     url: "invoice/add",
  //     method: "post",
  //     data: data
  //   });
  // };
  async getBankHistory(params) {
    return await this.API({
      url: `/wallet-history/list?userId=${params}`,
      method: "get",
    });
  };
}

export default BankTransactionApi;
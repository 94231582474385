import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2";
import Layout from "../../layout/fullpage";
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Icon,
} from "../../components";
import InputFields from "./components/InputFields";
import AuthApi from "../../api/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/global/reducer";
import { Spinner } from 'react-bootstrap';

const AuthLoginPage = ({ updateUser }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let api = new AuthApi();
  const [data, setData] = useState({});
  const [isValid, setIsvalidattion] = useState(false);
  const [passwordvisiblity, setPasswordVisiblity] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    checkFormValidation();
  }, [data]);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const checkFormValidation = () => {
    let { email, password } = data;
    if (validateEmail(email) && password) {
      setIsvalidattion(false);
    } else {
      setIsvalidattion(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: show loading button when api call
    if (data?.email && data?.password) {
      setIsLoading(true);
      try {
        await api.login(data).then((res) => {

          console.log('res.data :>> ', res.data);
          if (res?.status) {
            Swal.fire({
              icon: "success",
              text: res.message,
            });
            dispatch(setUser(res.data));
            localStorage.setItem("user_AYIN", JSON.stringify(res.data));
            navigate("/auth-login");
          } else {
            Swal.fire({
              icon: "error",
              text: res.message,
            });
          }
        });
      } catch (error) {
        console.log('error ===', error);
        Swal.fire({
          icon: "error",
          text: error.message,
        });
      } finally {
        setIsLoading(false);
      }

    } else {
      Swal.fire({
        icon: "error",
        text: "Cannot submit blank field!",
      });
    }
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse justify-content-center">
              <Col lg="8">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="brand-logo">
                      <Link to="/" className="logo-link">
                        <div className="logo-wrap">
                          <img
                            src="../../images/ayin-logo.jpeg"
                            alt="logo"
                          ></img>
                        </div>
                      </Link>
                    </div>
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title mb-1">Login to Account</h3>
                      <p className="small">
                        Please sign-in to your account and start the adventure.
                      </p>
                    </div>
                  </div>
                  <Form action="#">
                    <Row className="gy-3">
                      <InputFields
                        name="email"
                        required={null}
                        text={"Email"}
                        placeholder={"Enter email"}
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="exampleFormControlInputText4">
                          Password
                        </Form.Label>
                        <div className="form-control-wrap">
                          <div className="form-control-icon end" style={{ cursor: "pointer" }} onClick={() => setPasswordVisiblity(!passwordvisiblity)}>
                            <Icon name="eye"></Icon>
                          </div>
                          <Form.Control
                            type={passwordvisiblity ? "text" : "password"}
                            id="exampleFormControlInputText4"
                            name="password"
                            placeholder={"Enter Password"}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </Form.Group>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          <Form.Check
                            className="form-check-sm"
                            type="checkbox"
                            id="rememberMe"
                            label="Remember Me"
                          />
                          <Link to="/auths/auth-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="d-grid">
                          <Button type="submit" variant="darker" disabled={isValid} onClick={handleSubmit}>
                            {isLoading ? <Spinner animation="border" role="status" /> : 'Login to account'}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  {/* <div className="my-3 text-center">
                    <OverlineTitle className="overline-title-sep">
                      <span>OR</span>
                    </OverlineTitle>
                  </div>
                  <Row className="g-2">
                    <Col xxl="6">
                      <Button
                        href="#auth"
                        variant="outline-light"
                        className="w-100"
                      >
                        <Image
                          src="/images/icon/d.png"
                          alt=""
                          className="icon"
                        />
                        <span className="fw-medium">Continue with Google</span>
                      </Button>
                    </Col>
                    <Col xxl="6">
                      <Button
                        href="#auth"
                        variant="outline-light"
                        className="w-100"
                      >
                        <Image
                          src="/images/icon/b.png"
                          alt=""
                          className="icon"
                        />
                        <span className="fw-medium">
                          Continue with Facebook
                        </span>
                      </Button>
                    </Col>
                  </Row> */}
                  <div className="text-center mt-4">
                    <p className="small">
                      Don't have an account?{" "}
                      <Link to="/register">Register as Notary</Link>
                    </p>
                  </div>
                  <div className="text-center mt-1"> or </div>

                  <div className="text-center mt-1">
                    <p className="small">
                    
                      <Link to="/user-register">Register as User</Link>
                    </p>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default AuthLoginPage;

import { configureStore } from '@reduxjs/toolkit'
import userReducer from "../global/reducer"
import userLoginReducer from "../global/userSlice"
export const global_store = configureStore({
    reducer: {
        user: userReducer,
        login_user5:userLoginReducer
      },
})



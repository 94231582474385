// Prod Config
export const plan = [
  // {
  //   plan_name: "AYIN Online Mobile Notary℠ Membership",
  //   AYON_fee: 30,
  //   plan_price: 1,
  //   validity: "1",
  //   product_id: "prod_PLcTE9PBa6F3qZ",
  //   // price_id: "price_1OXdYmDomxDRUddIwyiwcTIX",
  //   price_id: "price_1ObGEmDomxDRUddIzJHL8buR",
  // },
  {
    plan_name: "AYIN Online Notary℠ Business Pro Membership",
    AYON_fee: 25,
    plan_price: 250,
    validity: "1",
    product_id: "prod_PLcUOJT2Q8LBaA",
    price_id: "price_1OXdZvDomxDRUddIKFE468bL",
  },
];

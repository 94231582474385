import React from "react";

function FormSelect({data, Form, Select }) {
    console.log("data",)
  return (
    <div>
      <Form.Group className="form-group">
        <div className="form-control-wrap">
          <Select>
            <option value="">Line Item</option>
            {
                 data.map((item)=>{
                    return(
                        <>
                         <option value="">{item.name}</option>
                        </>
                    )
                })
            }
          </Select>
        </div>
      </Form.Group>
    </div>
  );
}

export default FormSelect;

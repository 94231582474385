import BaseApi from "..";

function getAuthToken() {
  const data = {};

  // Iterate over all keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const value = localStorage.getItem(key);
    data[key] = value;
  }
  console.log("ldata=====", data)
  return data;
  // console.log("localStorage.getItem('authToken');=====", localStorage.getItem())
  // return localStorage.getItem('authToken');
}
class NotaryApi extends BaseApi {

  async list(data) {
    // await getAuthToken();
    return await this.API({
      url: "notary/list",
      method: "get",
      params: data,
    });
  };
  async update(data) {
    return await this.API({
      url: "notary/update",
      method: "post",
      data: data
    });
  };
}

export default NotaryApi;
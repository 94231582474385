import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import "./assets/scss/style.scss";
import { Provider } from 'react-redux'
import { global_store } from './redux/global/store';

// hello
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={global_store}>
      <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);


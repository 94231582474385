import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2";
import Layout from "../../layout/fullpage";
import { Icon } from "../../components";
import AuthApi from "../../api/auth";
import { Spinner } from 'react-bootstrap';

function AuthRegisterPage() {
  let api = new AuthApi();
  let navigate = useNavigate();
  
  const [data, setData] = useState({isNotary:true});
  const [isValid, setIsValidation] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleCheckboxChange = (checkboxId) => {
    if (checkboxId === "checkbox1") {
      setCheckbox1(!checkbox1);
    } else if (checkboxId === "checkbox2") {
      setCheckbox2(!checkbox2);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkbox1 && checkbox2 && data?.firstname && data?.lastname && data?.email) {
      if (data?.password === data?.confirmpassword) {
        setIsLoading(true); 
        try {
          const res = await api.register(data);
          if (res?.status) {
            Swal.fire({
              icon: "success",
              text: res.message,
            });
            navigate("/auth-login");
          } else {
            Swal.fire({
              icon: "error",
              text: res.message,
            });
          }
        } catch (error) {
          console.error("Error during registration:", error);
          Swal.fire({
            icon: "error",
            text: "An error occurred during registration.",
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Password mismatch!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Please select both checkboxes and fill in all required fields!",
      });
    }

    // if (checkbox1 && checkbox2 && data?.firstname && data?.lastname && data?.email) {
    //   if (data?.password === data?.confirmpassword) {
    //     await api.register(data).then((res) => {
    //       if (res?.status) {
    //         Swal.fire({
    //           icon: "success",
    //           text: res.message,
    //         });
    //         navigate("/auth-login");
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           text: res.message,
    //         });
    //       }
    //     });
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       text: "Password mismatch!",
    //     });
    //   }
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please select both checkboxes and fill in all required fields!",
    //   });
    // }
  };

  useEffect(() => {
    checkFormValidation();
  }, [data]);

  const checkFormValidation = () => {
    let { email, firstname, lastname, password, confirmpassword } = data;
    if (validateEmail(email) && firstname && lastname && password && confirmpassword) {
      setIsValidation(false);
    } else {
      setIsValidation(true);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Layout title="Register" centered>
      <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse justify-content-center">
            <Col lg="8">
              <Card.Body>
                <div className="nk-block-head text-center">
                  <div className="brand-logo">
                    <Link to="/" className="logo-link">
                      <div className="logo-wrap">
                        <img src="../../images/ayin-logo.jpeg" alt="logo"></img>
                      </div>
                    </Link>
                  </div>
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title mb-1">Create New Account</h3>
                    <p className="small">
                      Use your Email continue with AYIN App (it's free)!
                    </p>
                  </div>
                </div>
                <Form action="#" onSubmit={(e) => handleSubmit(e)}>
                  <Row className="gy-3">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlInputText4">First Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="exampleFormControlInputText1"
                        name="firstname"
                        placeholder="Enter First Name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlInputText4">Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="exampleFormControlInputText2"
                        name="lastname"
                        placeholder="Enter Last Name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlInputText4">Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="exampleFormControlInputText3"
                        name="companyname"
                        placeholder="Enter Company Name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlInputText4">Email Id</Form.Label>
                      <Form.Control
                        type="email"
                        id="exampleFormControlInputText4"
                        name="email"
                        placeholder="Enter your email"
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlInputText4">Password (required)</Form.Label>
                      <div className="form-control-wrap">
                        <div
                          className="form-control-icon end"
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisibility(!passwordVisibility)}
                        >
                          <Icon name="eye"></Icon>
                        </div>
                        <Form.Control
                          type={passwordVisibility ? "text" : "password"}
                          id="exampleFormControlInputText5"
                          name="password"
                          placeholder="Enter Password"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlInputText4">Confirm Password (required)</Form.Label>
                      <div className="form-control-wrap">
                        <div
                          className="form-control-icon end"
                          style={{ cursor: "pointer" }}
                          onClick={() => setPasswordVisibility(!passwordVisibility)}
                        >
                          <Icon name="eye"></Icon>
                        </div>
                        <Form.Control
                          type={passwordVisibility ? "text" : "password"}
                          id="exampleFormControlInputText6"
                          name="confirmpassword"
                          placeholder="Enter Confirm Password"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Form.Group>
                    <Col className="col-12">

                      <Form.Check
                        type="checkbox"
                        id="checkbox1"
                        label={
                          <span>
                            Membership Terms of Service and Rules -- By clicking here you agree to the{' '}
                            <Link
                              to={"https://www.ayininternationalinc.org/terms-of-service"}
                              className={`ml-2 ${checkbox1 ? '' : 'unclickable-link'}`}

                            >
                              Terms of Service and Rules
                            </Link>{' '}

                          </span>
                        }
                        onChange={() => handleCheckboxChange('checkbox1')}
                        checked={checkbox1}
                      />


                      <Form.Check
                        type="checkbox"
                        id="checkbox2"
                        label={
                          <span>
                            Payment Guidelines and Procedures -- By clicking here you agree to the {' '}
                            <Link
                              to={"https://www.ayininternationalinc.org/invoicing-policy"}
                              className={`ml-2 ${checkbox2 ? '' : 'unclickable-link'}`}
                            >
                              Payment Guidelines and Procedures.
                            </Link>{' '}

                          </span>
                        }
                        onChange={() => handleCheckboxChange('checkbox2')}
                        checked={checkbox2}
                      />

                    </Col>
                    <Col className="col-12">
                      <div className=" d-grid">
                        <Button type="submit" variant="darker" disabled={!checkbox1 || !checkbox2 || isValid || isLoading}>
                          {isLoading ? <Spinner animation="border" role="status" /> : 'Register'}
                        </Button>
                        {/* <Button type="submit" variant="darker" disabled={!checkbox1 || !checkbox2 || isValid}>
                          Register
                        </Button> */}
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div className="text-center mt-4">
                  <p className="small">
                    Already have an account? <Link to="/auth-login">Login</Link>
                  </p>
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
}

export default AuthRegisterPage;











// import React, { useEffect, useState } from "react";
// import { Row, Col, Card, Form, Button } from "react-bootstrap";
// import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import axios from "axios";
// import Layout from "../../layout/fullpage";
// import Swal from "sweetalert2/src/sweetalert2";
// import {
//   Media,
//   MediaGroup,
//   Image,
//   OverlineTitle,
//   Logo,
//   Icon,
// } from "../../components";
// import AuthApi from "../../api/auth";
// import InputFields from "./components/InputFields";

// function AuthRegisterPage() {
//   let api = new AuthApi();
//   let navigate = useNavigate();
//   const [data, setData] = useState({});
//   const [isValid,setIsvalidattion] = useState(false)
//   const[passwordvisiblity,setPasswordVisiblity]=useState(false)
//   useEffect(()=>{
//     checkFormValidation();
//   },[data])
//   const validateEmail = (email) => {
//     return String(email)
//       .toLowerCase()
//       .match(
//         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//       );
//   };
//   const checkFormValidation = () =>{
//     let{email,firstname,lastname,password,confirmpassword} = data;
//     if(validateEmail(email) &&firstname&&lastname&&password&&confirmpassword){
//       setIsvalidattion(false)
//     }else{
//       setIsvalidattion(true)
//     }
//   }
//   const handleChange = (e) => {
//     let { value, name } = e.target;
//     setData({ ...data, [name]: value });
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (data?.firstname && data?.lastname && data?.email) {
//       if (data?.password == data?.confirmpassword) {
//         await api.register(data).then((res) => {
//           if (res?.status) {
//             Swal.fire({
//               icon: "success",
//               text: res.message,
//             });
//             navigate("/auth-login");
//           } else {
//             Swal.fire({
//               icon: "error",
//               text: res.message,
//             });
//           }
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           text: "Password mismatch !",
//         });
//       }
//     } else {
//       Swal.fire({
//         icon: "error",
//         text: "Cannot submit blank field!",
//       });
//     }
//   };


//   return (
//     <Layout title="Register" centered>
//       <div className="container p-2 p-sm-4">
//         <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
//           <Row className="g-0 flex-lg-row-reverse justify-content-center">
//             <Col lg="8">
//               <Card.Body>
//                 <div className="nk-block-head text-center">
//                   <div className="brand-logo">
//                     <Link to="/" className="logo-link">
//                       <div className="logo-wrap">
//                         <img src="../../images/ayin-logo.jpeg" alt="logo"></img>
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="nk-block-head-content">
//                     <h3 className="nk-block-title mb-1">Create New Account</h3>
//                     <p className="small">
//                       Use your Email continue with AYIN App (it's free)!
//                     </p>
//                   </div>
//                 </div>
//                 <Form action="#" onSubmit={(e) => handleSubmit(e)}>
//                   <Row className="gy-3">
//                     <InputFields
//                       name="firstname"
//                       required={true}
//                       text={"First Name"}
//                       placeholder={"Enter First Name"}
//                       onChange={(e) => handleChange(e)}
//                     />
//                     <InputFields
//                       name="lastname"
//                       required={true}
//                       text={"Last Name"}
//                       placeholder={"Enter Last Name"}
//                       onChange={(e) => handleChange(e)}
//                     />
//                     <InputFields
//                       name="companyname"
//                       required={false  }
//                       text={"Company Name"}
//                       placeholder={"Enter company Name"}
//                       onChange={(e) => handleChange(e)}
//                     />
//                     <InputFields
//                       name="email"
//                       required={true}
//                       text={"Email Id"}
//                       placeholder={"Enter your email"}
//                       onChange={(e) => handleChange(e)}
//                     />
//                               <Form.Group className="form-group">
//                         <Form.Label htmlFor="exampleFormControlInputText4">
//                           Password (required)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <div className="form-control-icon end" style={{cursor:"pointer"}} onClick={()=>setPasswordVisiblity(!passwordvisiblity)}>
//                             <Icon name="eye"></Icon>
//                           </div>
//                           <Form.Control
//                             type={passwordvisiblity?"text":"password"}
//                             id="exampleFormControlInputText4"
//                             name="password"
//                             placeholder={"Enter Password"}
//                             onChange={(e) => handleChange(e)}
//                           />
//                         </div>
//                       </Form.Group>
//                               <Form.Group className="form-group">
//                         <Form.Label htmlFor="exampleFormControlInputText4">
//                         Confirm Password (required)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <div className="form-control-icon end" style={{cursor:"pointer"}} onClick={()=>setPasswordVisiblity(!passwordvisiblity)}>
//                             <Icon name="eye"></Icon>
//                           </div>
//                           <Form.Control
//                             type={passwordvisiblity?"text":"password"}
//                             id="exampleFormControlInputText4"
//                             name="confirmpassword"
//                             placeholder={"Enter confirm Password"}
//                             onChange={(e) => handleChange(e)}
//                           />
//                         </div>
//                       </Form.Group>
//                     {/* <InputFields
//                       name="confirmpassword"
//                       required={true}
//                       text={"Confirm Password"}
//                       placeholder={"Enter confirm Password"}
//                       onChange={(e) => handleChange(e)}
//                     /> */}
//                     <Col className="col-12">
//                       <div className=" d-grid">
//                         <Button type="submit" variant="darker" disabled={isValid} >
//                           Register
//                         </Button>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Form>
//                 <div className="text-center mt-4">
//                   <p className="small">
//                     Already have an account?{" "}
//                     <Link to="/auth-login">Login</Link>
//                   </p>
//                 </div>
//               </Card.Body>
//             </Col>
//           </Row>
//         </Card>
//       </div>
//     </Layout>
//   );
// }

// export default AuthRegisterPage;


// import React, { useEffect, useState } from "react";
// import { Row, Col, Card, Form, Button } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2/src/sweetalert2";
// import Layout from "../../layout/fullpage";
// import { Icon } from "../../components";
// import AuthApi from "../../api/auth";

// function AuthRegisterPage() {
//   let api = new AuthApi();
//   let navigate = useNavigate();
//   const [data, setData] = useState({});
//   const [isValid, setIsValidation] = useState(false);
//   const [passwordVisibility, setPasswordVisibility] = useState(false);
//   const [checkbox1, setCheckbox1] = useState(false);
//   const [checkbox2, setCheckbox2] = useState(false);

//   const handleChange = (e) => {
//     let { value, name } = e.target;
//     setData({ ...data, [name]: value });
//   };

//   const handleCheckboxChange = (checkboxId) => {
//     if (checkboxId === "checkbox1") {
//       setCheckbox1(!checkbox1);
//     } else if (checkboxId === "checkbox2") {
//       setCheckbox2(!checkbox2);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (checkbox1 && checkbox2 && data?.firstname && data?.lastname && data?.email) {
//       if (data?.password === data?.confirmpassword) {
//         await api.register(data).then((res) => {
//           if (res?.status) {
//             Swal.fire({
//               icon: "success",
//               text: res.message,
//             });
//             navigate("/auth-login");
//           } else {
//             Swal.fire({
//               icon: "error",
//               text: res.message,
//             });
//           }
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           text: "Password mismatch!",
//         });
//       }
//     } else {
//       Swal.fire({
//         icon: "error",
//         text: "Please select both checkboxes and fill in all required fields!",
//       });
//     }
//   };

//   useEffect(() => {
//     checkFormValidation();
//   }, [data]);

//   const checkFormValidation = () => {
//     let { email, firstname, lastname, password, confirmpassword } = data;
//     if (validateEmail(email) && firstname && lastname && password && confirmpassword) {
//       setIsValidation(false);
//     } else {
//       setIsValidation(true);
//     }
//   };

//   const validateEmail = (email) => {
//     return String(email)
//       .toLowerCase()
//       .match(
//         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//       );
//   };

//   return (
//     <Layout title="Register" centered>
//       <div className="container p-2 p-sm-4">
//         <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
//           <Row className="g-0 flex-lg-row-reverse justify-content-center">
//             <Col lg="8">
//               <Card.Body>
//                 <div className="nk-block-head text-center">
//                   <div className="brand-logo">
//                     <Link to="/" className="logo-link">
//                       <div className="logo-wrap">
//                         <img src="../../images/ayin-logo.jpeg" alt="logo"></img>
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="nk-block-head-content">
//                     <h3 className="nk-block-title mb-1">Create New Account</h3>
//                     <p className="small">
//                       Use your Email continue with AYIN App (it's free)!
//                     </p>
//                   </div>
//                 </div>
//                 <Form action="#" onSubmit={(e) => handleSubmit(e)}>
//                   <Row className="gy-3">
//                     <Form.Group className="form-group">
//                       <Form.Label htmlFor="exampleFormControlInputText4">First Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         id="exampleFormControlInputText1"
//                         name="firstname"
//                         placeholder="Enter First Name"
//                         onChange={(e) => handleChange(e)}
//                       />
//                     </Form.Group>
//                     <Form.Group className="form-group">
//                       <Form.Label htmlFor="exampleFormControlInputText4">Last Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         id="exampleFormControlInputText2"
//                         name="lastname"
//                         placeholder="Enter Last Name"
//                         onChange={(e) => handleChange(e)}
//                       />
//                     </Form.Group>
//                     <Form.Group className="form-group">
//                       <Form.Label htmlFor="exampleFormControlInputText4">Company Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         id="exampleFormControlInputText3"
//                         name="companyname"
//                         placeholder="Enter Company Name"
//                         onChange={(e) => handleChange(e)}
//                       />
//                     </Form.Group>
//                     <Form.Group className="form-group">
//                       <Form.Label htmlFor="exampleFormControlInputText4">Email Id</Form.Label>
//                       <Form.Control
//                         type="email"
//                         id="exampleFormControlInputText4"
//                         name="email"
//                         placeholder="Enter your email"
//                         onChange={(e) => handleChange(e)}
//                       />
//                     </Form.Group>
//                     <Form.Group className="form-group">
//                       <Form.Label htmlFor="exampleFormControlInputText4">Password (required)</Form.Label>
//                       <div className="form-control-wrap">
//                         <div
//                           className="form-control-icon end"
//                           style={{ cursor: "pointer" }}
//                           onClick={() => setPasswordVisibility(!passwordVisibility)}
//                         >
//                           <Icon name="eye"></Icon>
//                         </div>
//                         <Form.Control
//                           type={passwordVisibility ? "text" : "password"}
//                           id="exampleFormControlInputText5"
//                           name="password"
//                           placeholder="Enter Password"
//                           onChange={(e) => handleChange(e)}
//                         />
//                       </div>
//                     </Form.Group>
//                     <Form.Group className="form-group">
//                       <Form.Label htmlFor="exampleFormControlInputText4">Confirm Password (required)</Form.Label>
//                       <div className="form-control-wrap">
//                         <div
//                           className="form-control-icon end"
//                           style={{ cursor: "pointer" }}
//                           onClick={() => setPasswordVisibility(!passwordVisibility)}
//                         >
//                           <Icon name="eye"></Icon>
//                         </div>
//                         <Form.Control
//                           type={passwordVisibility ? "text" : "password"}
//                           id="exampleFormControlInputText6"
//                           name="confirmpassword"
//                           placeholder="Enter Confirm Password"
//                           onChange={(e) => handleChange(e)}
//                         />
//                       </div>
//                     </Form.Group>
//                     <Col className="col-12">

//                       <Form.Check
//                         type="checkbox"
//                         id="checkbox1"
//                         label={
//                           <span>
//                             Membership Terms of Service and Rules -- By clicking here you agree to the{' '}
//                             <Link
//                               to={"https://www.ayininternationalinc.org/terms-of-service"}
//                               className={`ml-2 ${checkbox1 ? '' : 'unclickable-link'}`}

//                             >
//                               Terms of Service and Rules
//                             </Link>{' '}

//                           </span>
//                         }
//                         onChange={() => handleCheckboxChange('checkbox1')}
//                         checked={checkbox1}
//                       />


//                       <Form.Check
//                         type="checkbox"
//                         id="checkbox2"
//                         label={
//                           <span>
//                             Payment Guidelines and Procedures -- By clicking here you agree to the {' '}
//                             <Link
//                               to={"https://www.ayininternationalinc.org/invoicing-policy"}
//                               className={`ml-2 ${checkbox2 ? '' : 'unclickable-link'}`}
//                             >
//                               Payment Guidelines and Procedures.
//                             </Link>{' '}

//                           </span>
//                         }
//                         onChange={() => handleCheckboxChange('checkbox2')}
//                         checked={checkbox2}
//                       />

//                     </Col>
//                     <Col className="col-12">
//                       <div className=" d-grid">
//                         <Button type="submit" variant="darker" disabled={!checkbox1 || !checkbox2 || isValid}>
//                           Register
//                         </Button>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Form>
//                 <div className="text-center mt-4">
//                   <p className="small">
//                     Already have an account? <Link to="/auth-login">Login</Link>
//                   </p>
//                 </div>
//               </Card.Body>
//             </Col>
//           </Row>
//         </Card>
//       </div>
//     </Layout>
//   );
// }

// export default AuthRegisterPage;











// // import React, { useEffect, useState } from "react";
// // import { Row, Col, Card, Form, Button } from "react-bootstrap";
// // import { Link, useNavigate, useSearchParams } from "react-router-dom";
// // import axios from "axios";
// // import Layout from "../../layout/fullpage";
// // import Swal from "sweetalert2/src/sweetalert2";
// // import {
// //   Media,
// //   MediaGroup,
// //   Image,
// //   OverlineTitle,
// //   Logo,
// //   Icon,
// // } from "../../components";
// // import AuthApi from "../../api/auth";
// // import InputFields from "./components/InputFields";

// // function AuthRegisterPage() {
// //   let api = new AuthApi();
// //   let navigate = useNavigate();
// //   const [data, setData] = useState({});
// //   const [isValid,setIsvalidattion] = useState(false)
// //   const[passwordvisiblity,setPasswordVisiblity]=useState(false)
// //   useEffect(()=>{
// //     checkFormValidation();
// //   },[data])
// //   const validateEmail = (email) => {
// //     return String(email)
// //       .toLowerCase()
// //       .match(
// //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// //       );
// //   };
// //   const checkFormValidation = () =>{
// //     let{email,firstname,lastname,password,confirmpassword} = data;
// //     if(validateEmail(email) &&firstname&&lastname&&password&&confirmpassword){
// //       setIsvalidattion(false)
// //     }else{
// //       setIsvalidattion(true)
// //     }
// //   }
// //   const handleChange = (e) => {
// //     let { value, name } = e.target;
// //     setData({ ...data, [name]: value });
// //   };
// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     if (data?.firstname && data?.lastname && data?.email) {
// //       if (data?.password == data?.confirmpassword) {
// //         await api.register(data).then((res) => {
// //           if (res?.status) {
// //             Swal.fire({
// //               icon: "success",
// //               text: res.message,
// //             });
// //             navigate("/auth-login");
// //           } else {
// //             Swal.fire({
// //               icon: "error",
// //               text: res.message,
// //             });
// //           }
// //         });
// //       } else {
// //         Swal.fire({
// //           icon: "error",
// //           text: "Password mismatch !",
// //         });
// //       }
// //     } else {
// //       Swal.fire({
// //         icon: "error",
// //         text: "Cannot submit blank field!",
// //       });
// //     }
// //   };


// //   return (
// //     <Layout title="Register" centered>
// //       <div className="container p-2 p-sm-4">
// //         <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
// //           <Row className="g-0 flex-lg-row-reverse justify-content-center">
// //             <Col lg="8">
// //               <Card.Body>
// //                 <div className="nk-block-head text-center">
// //                   <div className="brand-logo">
// //                     <Link to="/" className="logo-link">
// //                       <div className="logo-wrap">
// //                         <img src="../../images/ayin-logo.jpeg" alt="logo"></img>
// //                       </div>
// //                     </Link>
// //                   </div>
// //                   <div className="nk-block-head-content">
// //                     <h3 className="nk-block-title mb-1">Create New Account</h3>
// //                     <p className="small">
// //                       Use your Email continue with AYIN App (it's free)!
// //                     </p>
// //                   </div>
// //                 </div>
// //                 <Form action="#" onSubmit={(e) => handleSubmit(e)}>
// //                   <Row className="gy-3">
// //                     <InputFields
// //                       name="firstname"
// //                       required={true}
// //                       text={"First Name"}
// //                       placeholder={"Enter First Name"}
// //                       onChange={(e) => handleChange(e)}
// //                     />
// //                     <InputFields
// //                       name="lastname"
// //                       required={true}
// //                       text={"Last Name"}
// //                       placeholder={"Enter Last Name"}
// //                       onChange={(e) => handleChange(e)}
// //                     />
// //                     <InputFields
// //                       name="companyname"
// //                       required={false  }
// //                       text={"Company Name"}
// //                       placeholder={"Enter company Name"}
// //                       onChange={(e) => handleChange(e)}
// //                     />
// //                     <InputFields
// //                       name="email"
// //                       required={true}
// //                       text={"Email Id"}
// //                       placeholder={"Enter your email"}
// //                       onChange={(e) => handleChange(e)}
// //                     />
// //                               <Form.Group className="form-group">
// //                         <Form.Label htmlFor="exampleFormControlInputText4">
// //                           Password (required)
// //                         </Form.Label>
// //                         <div className="form-control-wrap">
// //                           <div className="form-control-icon end" style={{cursor:"pointer"}} onClick={()=>setPasswordVisiblity(!passwordvisiblity)}>
// //                             <Icon name="eye"></Icon>
// //                           </div>
// //                           <Form.Control
// //                             type={passwordvisiblity?"text":"password"}
// //                             id="exampleFormControlInputText4"
// //                             name="password"
// //                             placeholder={"Enter Password"}
// //                             onChange={(e) => handleChange(e)}
// //                           />
// //                         </div>
// //                       </Form.Group>
// //                               <Form.Group className="form-group">
// //                         <Form.Label htmlFor="exampleFormControlInputText4">
// //                         Confirm Password (required)
// //                         </Form.Label>
// //                         <div className="form-control-wrap">
// //                           <div className="form-control-icon end" style={{cursor:"pointer"}} onClick={()=>setPasswordVisiblity(!passwordvisiblity)}>
// //                             <Icon name="eye"></Icon>
// //                           </div>
// //                           <Form.Control
// //                             type={passwordvisiblity?"text":"password"}
// //                             id="exampleFormControlInputText4"
// //                             name="confirmpassword"
// //                             placeholder={"Enter confirm Password"}
// //                             onChange={(e) => handleChange(e)}
// //                           />
// //                         </div>
// //                       </Form.Group>
// //                     {/* <InputFields
// //                       name="confirmpassword"
// //                       required={true}
// //                       text={"Confirm Password"}
// //                       placeholder={"Enter confirm Password"}
// //                       onChange={(e) => handleChange(e)}
// //                     /> */}
// //                     <Col className="col-12">
// //                       <div className=" d-grid">
// //                         <Button type="submit" variant="darker" disabled={isValid} >
// //                           Register
// //                         </Button>
// //                       </div>
// //                     </Col>
// //                   </Row>
// //                 </Form>
// //                 <div className="text-center mt-4">
// //                   <p className="small">
// //                     Already have an account?{" "}
// //                     <Link to="/auth-login">Login</Link>
// //                   </p>
// //                 </div>
// //               </Card.Body>
// //             </Col>
// //           </Row>
// //         </Card>
// //       </div>
// //     </Layout>
// //   );
// // }

// // export default AuthRegisterPage;

import BaseApi from "..";

class AuthApi extends BaseApi {
  async login(data) {
    return await this.API({
      url: "client/login",
      method: "post",
      data: data,
    });
  };
  async logout(params) {
    return await this.API({
      url: `client/logout/${params}`,
      method: "get",
    });
  };
  async register(data) {
    return await this.API({
      url: "client/register",
      method: "post",
      data: data,
    });
  }
  async get_user_detail(data) {
    return await this.API({
      url: "client/get_user_detail",
      method: "post",
      data: data,
    });
  }
  async get_user_data() {
    return await this.API({
      url: "client/user-data",
      method: "get",
    });
  }
  async dashboardData(params) {
    return await this.API({
      url: `/client/list/dashboard-all-data?userId=${params}`,
      method: "get",
    });
  };

  async getClient(id) {
    return await this.API({
      url: `/client/list/${id}`,
      method: "get",
    });
  };

  async getSingleClientAllData(id) {
    return await this.API({
      url: `/client/${id}`,
      method: "get",
    });
  };

}

export default AuthApi;
import axios from "axios";
import { useContext } from "react";
import Swal from "sweetalert2/src/sweetalert2";
// const API_URL = process.env.REACT_APP_API_URL;
// const API_URL =  "http://localhost:5001"
const API_URL = require("../config").API_URL;

class BaseApi {
  //, additionalHeaders = {}
  API = async (options, onUploadPrgress, onDownloadPrgress) => {
    
    let json = JSON.parse(localStorage.getItem("user_AYIN")) ;
    let token = json?json.token:null

    let authHeader = "";
    if (token) {
      authHeader = `${token}`;
    }

    const client = axios.create({
      baseURL: API_URL,
      headers: { token: authHeader }, //, ...additionalHeaders
      onUploadProgress: onUploadPrgress,
      onDownloadProgress: onDownloadPrgress,
    });

    return client(options)
      .then((response) => {
        if (response.data && response.data.error) {
          // window.location.reload();
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log("error=====", error); 
        // window.location.href("/")
        // If response status is 401, remove token from localStorage
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user_AYIN");
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Session Expired, Please Login Again",
          });
          localStorage.removeItem("user_AYIN");
          window.location.href = "/auth-login";
          // window.location.reload();
        }
        else{
          // Removed this as any 400 response will be handled by the component, and this shows unnecessary popups
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops",
          //   text: `error.response.status: ${error?.response?.status}`,
          // });
        }
      } );
  };
}

export default BaseApi;

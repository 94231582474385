import React from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
function InputFields({value, name, required, text, placeholder, onChange }) {
  return (
    <>
      <Col className="col-12">
        <Form.Group className="form-group">
          <Form.Label htmlFor={name}>{text}</Form.Label> {required != null  && (required ? "(required)" : "(optional)")}
          <div className="form-control-wrap">
            <Form.Control
            value={value}
              type={name =="password" || name == "confirmpassword" ? "password":"text"}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
            />
          </div>
        </Form.Group>
      </Col>
    </>
  );
}

export default InputFields;

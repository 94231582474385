
import { createSlice } from '@reduxjs/toolkit';

const initialState={}
const loginUserSlice = createSlice({
  name: 'login_user1',// name show in redux dev tools as list
  initialState,
  reducers: {
    setLoginUser: (state, action) => {
      state.login_user3 = action.payload; // in redux dev tool store all data inside login_user3 object
    },
  },
});

export const { setLoginUser } = loginUserSlice.actions;// use for dispatch 
export const selectLoginUser = (state) => state.login_user5; // use for useSelector login_user5 must be match with store reducer
export default loginUserSlice.reducer;
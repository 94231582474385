import React from 'react'
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/global/reducer';

function Logo({small}) {
    let { user } = useSelector(selectUser);
    var firstChar = user?.firstname.charAt(0).toUpperCase()
    var secondChar = user?.lastname.charAt(0).toUpperCase()
  return (
    <div>
      <p className={small?`no-logo-small`:`no-logo`}>{firstChar}{secondChar}</p>
    </div>
  )
}

export default Logo

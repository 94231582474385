import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import AuthRegister from "../../pages/auths/AuthRegister";
import AuthLogin from "../../pages/auths/AuthLogin";
import AuthReset from "../../pages/auths/AuthReset";
import AuthUserRegister from '../../pages/auths/AuthUserRegister';
function PublicRoute() {
  return (
    <>
      <Routes>
          <Route>
            <Route path="/register" element={<AuthRegister />} />
            <Route path="/user-register" element={<AuthUserRegister />} />
            <Route
              path="/auth-login"
              element={<AuthLogin />}
            />
            <Route path="auth-reset" element={<AuthReset />} />
            <Route path="/*" element={<Navigate to="/auth-login" />} />
          </Route>
        </Routes>
    </>
  )
}

export default PublicRoute
